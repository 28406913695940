<template>
  <v-app-bar
    dense
    fixed
    :color="$vuetify.theme.dark ? undefined : 'white'"
    app
    style="width: auto"
    elevate-on-scroll
  >
    {{ change() }}

    <v-btn
      fab
      small
      icon
      class="mr-2"
      @click="
        $vuetify.breakpoint.mdAndDown
          ? setDrawer(!drawer)
          : $emit('input', !value)
      "
    >
      <v-icon v-if="value">mdi-view-quilt</v-icon>
      <v-icon v-else>mdi-dots-vertical</v-icon>
    </v-btn>

    <v-toolbar-title class="hidden-sm-and-down pr-5">{{
      $t($route.name)
    }}</v-toolbar-title>
    <v-spacer />
    <v-tooltip bottom v-if="isInRole(0) && this.$vuetify.breakpoint.mobile">
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab icon v-bind="attrs" v-on="on" @click="openQrCodeReader()">
          <v-icon large color="light-blue darken-1">mdi-qrcode-scan</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("abs.AttendanceRegistration") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab icon @click="changeSize()" v-bind="attrs" v-on="on">
          <v-icon large color=" darken-1">mdi-format-size</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("setting.size") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab icon @click="langChang()" v-bind="attrs" v-on="on">
          <v-icon large color="light-blue darken-1">mdi-translate</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("setting.lang") }}</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn fab icon v-bind="attrs" v-on="on" @click="reColor()">
          <v-icon large color="amber">mdi-theme-light-dark</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("setting.darkMode") }}</span>
    </v-tooltip>

    <!-- <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          min-width="0"
          icon
          v-bind="attrs"
          v-on="on"
          @click.stop="setDrawerMessage(!drawerMessage)"
        >
          <v-icon large color="cyan accent-4">mdi-chat-processing</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("appBar.chat") }}</span>
    </v-tooltip> -->
    <v-menu
      max-height="500"
      width="350px"
      bottom
      :left="$i18n.locale == 'ar' ? false : true"
      :origin="$i18n.locale == 'ar' ? 'top left' : 'top right'"
      offset-y
      transition="scale-transition"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn fab min-width="0" icon v-bind="attrs" v-on="on" @click="getNoti">
          <v-badge
            color="red"
            overlap
            :content="$store.state.allNotifications"
            :value="$store.state.allNotifications"
          >
            <template v-slot:badge>
              <span class="">{{ $store.state.allNotifications }}</span>
            </template>

            <v-icon large color="amber ">mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>
      <v-list width="350px" nav class="pa-0 py-1">
        <v-list-item
          link
          class="py-0"
          v-for="(n, i) in notifications"
          :key="`item-${i}`"
          @click="openNoti(n)"
        >
          <v-alert
            outlined
            class="ma-0"
            dense
            :text="!n.isRead"
            :color="n.isRead ? 'blue-grey' : 'primary'"
            icon="mdi-school-outline"
            v-if="n.notifyType == 7"
          >
          <div dir="auto">
            {{
              `تم رفع درجة مادة ${n.text} من قبل الاستاذ  `
            }}
            <strong>{{ n.fromName }}</strong>
            <v-row align="center" no-gutters>
              <v-col
                :class="$i18n.locale == 'ar' ? 'text-left' : 'text-right'"
                class="pt-4"
              >
                <p class="fontSize pb-0 mb-0">
                  {{ n.datex | moment("from", "now") }}
                </p>
              </v-col>
            </v-row></div>
          </v-alert>
          <v-alert
            width="100%"
            outlined
            class="ma-0"
            dense
            :text="!n.isRead"
            :color="n.isRead ? 'blue-grey' : 'primary'"
            icon="mdi-email-outline"
            v-if="n.notifyType == 8"
          >
          <div dir="auto">
            {{ `تم استلام بريد من ` }}
            <strong>{{ n.fromName }}</strong>
            <v-row align="center" no-gutters>
              <v-col
                :class="$i18n.locale == 'ar' ? 'text-left' : 'text-right'"
                class="pt-4"
              >
                <p class="fontSize pb-0 mb-0">
                  {{ n.datex | moment("from", "now") }}
                </p>
              </v-col>
            </v-row></div>
          </v-alert>
          <v-alert
            outlined
            class="ma-0"
            dense
            :text="!n.isRead"
            :color="n.isRead ? 'blue-grey' : 'primary'"
            icon="mdi-puzzle-outline"
            v-if="n.notifyType == 9"
          >
          <div dir="auto">
            {{ `لديك امتحان في مادة ` }}

            <strong>{{ n.text }}</strong>
            {{ `(${n.jsonData.Title}) يبدا بتاريخ ` }}
            {{ n.jsonData.StartDate | moment("YYYY/MM/DD - h:mm A ") }}
            {{ ` من قبل الاستاذ ` }}
            <strong>{{ n.fromName }}</strong>
            <v-row align="center" no-gutters>
              <v-col
                :class="$i18n.locale == 'ar' ? 'text-left' : 'text-right'"
                class="pt-4"
              >
                <p class="fontSize pb-0 mb-0">
                  {{ n.datex | moment("from", "now") }}
                </p>
              </v-col>
            </v-row></div>
          </v-alert>
          <v-alert
            outlined
            class="ma-0"
            dense
            :text="!n.isRead"
            :color="n.isRead ? 'blue-grey' : 'primary'"
            icon="mdi-bookshelf"
            v-if="n.notifyType == 10"
          > <div dir="auto">
            {{
              ` تم رفع محاضرة جديدة لمادة ${n.text} من قبل الاستاذ `
            }}
            <strong>{{ n.fromName }}</strong>
            <v-row align="center" no-gutters>
              <v-col
                :class="$i18n.locale == 'ar' ? 'text-left' : 'text-right'"
                class="pt-4"
              >
                <p class="fontSize pb-0 mb-0">
                  {{ n.datex | moment("from", "now") }}
                </p>
              </v-col>
            </v-row></div>
          </v-alert>
          <v-alert
            outlined
            class="ma-0"
            dense
            :text="!n.isRead"
            :color="n.isRead ? 'blue-grey' : 'primary'"
            icon="mdi-note-check-outline"
            v-if="n.notifyType == 11"
          >
          <div dir="auto">
            {{
              `تم عرض نتيجة امتحان (${n.jsonData.Title}) من قبل الاستاذ `
            }}
            <strong>{{ n.fromName }}</strong>
            <v-row align="center" no-gutters>
              <v-col
                :class="$i18n.locale == 'ar' ? 'text-left' : 'text-right'"
                class="pt-4"
              >
                <p class="fontSize pb-0 mb-0">
                  {{ n.datex | moment("from", "now") }}
                </p>
              </v-col>
            </v-row></div>
          </v-alert>
          <v-alert
            outlined
            class="ma-0"
            dense
            :text="!n.isRead"
            :color="n.isRead ? 'blue-grey' : 'primary'"
            icon="mdi-forum"
            v-if="n.notifyType == 12"
          >   <div dir="auto">
            {{ `${n.text} من قبل ` }}
            <strong>{{ n.fromName }}</strong>
            <v-row align="center" no-gutters>
              <v-col
                :class="$i18n.locale == 'ar' ? 'text-left' : 'text-right'"
                class="pt-4"
              >
                <p class="fontSize pb-0 mb-0">
                  {{ n.datex | moment("from", "now") }}
                </p>
              </v-col>
            </v-row></div>
          </v-alert>
          <v-alert 
            outlined
            class="ma-0 forum-reply-notify"
            dense
            :text="!n.isRead"
            :color="n.isRead ? 'blue-grey' : 'primary'"
            :icon="getIcon({ forumType: n.jsonData.ForumType })"
            v-if="n.notifyType == 13"
          >
          <div dir="auto">
            {{
              `${n.text} (${n.jsonData.Title})
              من قبل `
            }}
            <strong>{{ n.fromName }}</strong>
            <v-row align="center" no-gutters>
              <v-col
                :class="$i18n.locale == 'ar' ? 'text-left' : 'text-right'"
                class="pt-4"
              >
                <p class="fontSize pb-0 mb-0">
                  {{ n.datex | moment("from", "now") }}
                </p>
              </v-col>
            </v-row></div>
          </v-alert>
          <v-alert
            outlined
            class="ma-0"
            dense
            :text="!n.isRead"
            :color="n.isRead ? 'blue-grey' : 'primary'"
            icon="mdi-comment"
            v-if="n.notifyType == 14"
          >
          <div dir="auto">
            {{
              `${n.text} 
              من قبل `
            }}
            <strong>{{ n.fromName }}</strong>
            <v-row align="center" no-gutters>
              <v-col
                :class="$i18n.locale == 'ar' ? 'text-left' : 'text-right'"
                class="pt-4"
              >
                <p class="fontSize pb-0 mb-0" v-if="n.createdDate">
                  {{ n.createdDate | moment("from", "now") }}
                </p>
              </v-col>
            </v-row></div>
          </v-alert>
          <v-alert
            outlined
            class="ma-0"
            dense
            :text="!n.isRead"
            :color="n.isRead ? 'blue-grey' : 'primary'"
            icon="mdi-star"
            v-if="n.notifyType == 15"
          >
            <div dir="auto">
              {{
                `${n.text} 
                من قبل `
              }}
              <strong>{{ n.fromName }}</strong>
              <v-row align="center" no-gutters>
                <v-col
                  :class="$i18n.locale == 'ar' ? 'text-left' : 'text-right'"
                  class="pt-4"
                >
                  <p class="fontSize pb-0 mb-0" v-if="n.datex">
                    {{ n.datex | moment("from", "now") }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-alert>
        </v-list-item>
        <v-list-item>
          <v-btn block text color="primary" @click="getMoreMail">{{
            $t("noti.ShowMore")
          }}</v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-menu
      bottom
      min-width="200"
      :left="$i18n.locale == 'ar' ? false : true"
      :origin="$i18n.locale == 'ar' ? 'top left' : 'top right'"
      offset-y
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn fab min-width="0" icon v-bind="attrs" v-on="on">
          <v-icon large>mdi-account-circle</v-icon>
        </v-btn>
      </template>
      <v-list :tile="false" flat nav>
        <template v-for="(p, i) in profile">
          <v-divider v-if="p.divider" :key="`divider-${i}`" class="mb-2 mt-2" />
          <app-bar-item
            v-else
            :key="`item-${i}`"
            :to="p.to"
            :class="$vuetify.theme.dark ? 'white--text' : ''"
          >
            <v-list-item-title>{{ p.title }}</v-list-item-title>
          </app-bar-item>
        </template>
      </v-list>
    </v-menu>

    <v-dialog v-model="dialogQrCodeOpen" fullscreen v-if="isInRole(0)">
      <v-card flat v-if="dialogQrCodeOpen">
        <v-toolbar color="primary darken-1" dark
          >{{ $t("abs.AttendanceRegistration") }}
          <v-spacer></v-spacer>
          <v-btn large icon dark @click="dialogQrCodeOpen = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row class="ma-2">
            <v-col cols="12" md="3"> </v-col>
            <v-col cols="12" md="6">
              <p
                :style="
                  registeredStatus == 1
                    ? 'text-align: center; font-size: large; color: green'
                    : 'text-align: center; font-size: large; color: red'
                "
                v-if="errorLog"
                class="mt-5"
              >
                {{ errorLog }}
              </p>

              <qrcode-stream
                @decode="onDecodeQrCode"
                @init="onInitQrCode"
                :track="paintBoundingBox"
                :camera="camera"
              >
                <div class="loading-indicator" v-if="loading">
                  {{ $t("Loading") }}
                </div>
                <div v-show="showScanConfirmation" class="scan-confirmation">
                  <img
                    :src="registeredStatus == 1 ? secussImg : errorImg"
                    alt="Checkmark"
                    width="128px"
                  />
                </div>
                <v-btn rounded class="ma-3" @click="switchCamera">
                  <v-icon> mdi-camera-switch </v-icon>
                </v-btn>
              </qrcode-stream>
            </v-col>
            <v-col cols="12" md="3"> </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app-bar>
</template>

<script>
import moment from "moment";
// import { disconnect } from "../../../../userHub";
import Axios from "axios";
// Components
import { VHover, VListItem } from "vuetify/lib";
// Utilities
import { mapState, mapMutations } from "vuex";
import { QrcodeStream } from "vue-qrcode-reader";
import { bus } from "../../../../main";

export default {
  name: "DashboardCoreAppBar",
  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) =>
              h(
                VListItem,
                {
                  attrs: this.$attrs,
                  class: {
                    "black--text": !hover,
                    "white--text secondary elevation-12": hover,
                  },
                  props: {
                    activeClass: "",
                    dark: hover,
                    link: true,
                    ...this.$attrs,
                  },
                },
                this.$slots.default
              ),
          },
        });
      },
    },
    QrcodeStream,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      size: 100,
      dialogQrCodeOpen: false,
      errorLog: null,
      loading: false,
      camera: "auto",
      currentCamera: "auto",
      registeredStatus: 0,
      errorImg: require("../../../../assets/crossmark.svg"),
      secussImg: require("../../../../assets/checkmark.svg"),
      result: null,
      showScanConfirmation: false,
      noRearCamera: false,
      noFrontCamera: false,
      numOfMail: 5,
      notifications: [],
      profile: [
        {
          title: this.$t("appBar.profile"),
          to: {
            path: "profile",
          },
        },
        // {
        //   title: "اعدادات",
        //   to: {
        //     path: "setting",
        //   },
        // },
        { divider: true },
        {
          title: this.$t("appBar.logout"),
          to: {
            name: "Login",
          },
        },
      ],
      forumType: {
        Announcements: 0,
        News: 1,
        Events: 2,
        Files: 3,
        Subjects: 4,
        Category: 5,
      },
    };
  },

  computed: {
    ...mapState(["drawer", "drawerMessage"]),
  },

  methods: {
    changeSize() {
      this.size == 110 ? (this.size = 100) : (this.size = 110);
      document.body.style.zoom = `${this.size}%`;
    },
    openNoti(item) {
      if (item.notifyType == 7) {
        this.$router
          .push({
            path: `/studentDegree`,
            replace: true,
          })
          .catch(() => {})
          .finally(() => {
            bus.$emit("showDegreeDialog", item.sourceGuid);
          });
      }
      if (item.notifyType == 8) {
        this.$router.push({
          path: `/showMail=${item.sourceGuid}&0`,
          params: { id: item.sourceGuid, type: 0 },
          replace: true,
        });
      } else if (item.notifyType == 9) {
        this.$router.push({
          path: `/enterExam=${item.sourceGuid}`,
          params: { id: item.sourceGuid },
        });
      } else if (item.notifyType == 10) {
        let url = "";
        if (item.jsonData) {
          url = `/lecOne/${item.sourceGuid}/${item.jsonData.folderGuid}`;
        } else {
          url = `/lecOne/${item.sourceGuid}`;
        }

        this.$router.push({
          path: url,
          replace: true,
        });
      } else if (item.notifyType == 11) {
        this.$router
          .push({
            path: `/exams`,
            params: { id: item.sourceGuid },
            replace: true,
          })
          .catch(() => {})
          .finally(() => {
            bus.$emit("showExamResultDialog", item.sourceGuid);
          });
      } else if (item.notifyType == 12 || item.notifyType == 13) {
        this.$router
          .push({
            path: `/forums?topicGuid=${item.sourceGuid}`,
            replace: true,
          })
          .catch(() => {})
          .finally(() => {
            bus.$emit("selectContent", item.jsonData.ForumTopicContentGuid);
          });
      } else if (item.notifyType == 14) {
        this.$router
          .push({
            path: `/forums?topicGuid=${item.jsonData.ForumTopicGuid}`,
            replace: true,
          })
          .catch(() => {})
          .finally(() => {
            bus.$emit("openRepliesDialog", item.sourceGuid);
          });
      } else if (item.notifyType == 15) {
        this.$router
          .push({
            path: `/forums?topicGuid=${item.jsonData.ForumTopicGuid}`,
            replace: true,
          })
          .catch(() => {})
          .finally(() => {
            bus.$emit("openRatesDialog", item.sourceGuid);
          });
      }
    },
    getMoreMail() {
      this.numOfMail += 5;
      this.getNoti();
    },
    getNoti() {
      Axios.get(`Notifications/Get?skip=0&take=${this.numOfMail}`).then(
        (res) => {
          this.notifications = res.data.data;
          for (let i = 0; i < this.notifications.length; i++) {
            if (this.notifications[i].jsonData) {
              this.notifications[i].jsonData = JSON.parse(
                this.notifications[i].jsonData
              );
            }
          }
          // console.log(this.notifications)
        }
      );
    },
    change() {
      this.profile[0].title = this.$t("appBar.profile");
      this.profile[2].title = this.$t("appBar.logout");
    },
    reColor() {
      // this.$router.go(0);
      localStorage.setItem("darkMode", !this.$vuetify.theme.dark);
      location.reload();
      // if (this.$vuetify.theme.dark) {
      //   this.setScrim("#313942");
      // } else {
      //   this.setScrim("#272727");
      // }
    },

    openQrCodeReader() {
      this.dialogQrCodeOpen = true;
    },
    async onDecodeQrCode(content) {
      this.result = content;
      let closeWindow = false;
      this.registeredStatus = 0;
      if (content) {
        this.pause();

        this.axios
          .post(`StudentsAbsence/QrCodeRegistration`, {
            Token: content,
          })
          .then((res) => {
            this.registeredStatus = res.data.statusCode;
            if (res.data.statusCode == 1) {
              this.errorLog = this.$t("abs.Registered");
              closeWindow = true;
            } else if (res.data.statusCode == 2) {
              this.errorLog = this.$t("abs.AlreadyRegistered");
            } else if (res.data.statusCode == 3) {
              this.errorLog = this.$t("abs.RegistrationExpiry");
            } else {
              this.$swal.fire({
                title: this.$t("AnErrorOccurredDuringTheProcess"),
                text: "",
                icon: "error",
                confirmButtonText: this.$t("close"),
              });
            }
            // console.log(res);
          })
          .catch(() => {
            this.$swal.fire({
              title: this.$t("AnErrorOccurredDuringTheProcess"),
              text: "",
              icon: "error",
              confirmButtonText: this.$t("close"),
            });
          })
          .finally(async () => {
            await this.timeout(2000);
            if (closeWindow) {
              this.dialogQrCodeOpen = false;
            }
            this.unpause();
          });
      }
    },
    unpause() {
      this.camera = this.currentCamera;
    },
    pause() {
      this.camera = "off";
    },
    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
    async onInitQrCode(promise) {
      this.loading = true;
      this.errorLog = null;
      this.showScanConfirmation = false;
      this.registeredStatus = 0;
      try {
        this.errorLog = null;
        await promise;
      } catch (error) {
        const triedFrontCamera = this.camera === "front";
        const triedRearCamera = this.camera === "rear";
        const cameraMissingError = error.name === "OverconstrainedError";
        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true;
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true;
        }

        if (error.name === "NotAllowedError") {
          this.errorLog = "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          this.errorLog = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.errorLog = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.errorLog = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.errorLog = `ERROR: installed cameras are not suitable [${this.camera}]`;
        } else if (error.name === "StreamApiNotSupportedError") {
          this.errorLog = "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.errorLog =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          console.log(error);
          this.errorLog = `ERROR: Camera error (${error.name})`;
        }
      } finally {
        this.loading = false;
        this.showScanConfirmation = this.camera === "off";
      }
    },
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;

        ctx.lineWidth = 5;
        ctx.strokeStyle = "#007bff";
        ctx.strokeRect(x, y, width, height);
      }
    },
    switchCamera() {
      if (this.noRearCamera && this.noRearCamera) {
        this.camera = "auto";
        return;
      }
      switch (this.camera) {
        case "auto" && !this.noFrontCamera:
          this.camera = "front";
          break;
        case "front" && !this.noRearCamera:
          this.camera = "rear";
          break;
        case "rear" && !this.noFrontCamera:
          this.camera = "front";
          break;
        default:
          this.camera = "auto";
      }
      this.currentCamera = this.camera;
    },
    getIcon(item) {
      switch (item.forumType) {
        case this.forumType.Announcements:
          return "mdi mdi-bullhorn-variant";
        case this.forumType.News:
          return "mdi mdi-newspaper-variant-outline";
        case this.forumType.Events:
          return "mdi mdi-calendar-multiple";
        case this.forumType.Files:
          return "mdi mdi-file-multiple";
        case this.forumType.Subjects:
          return "mdi mdi-book-open";
        default:
          return "mdi mdi-forum";
      }
    },

    ...mapMutations({
      setScrim: "SET_SCRIM",
    }),
    langChang() {
      this.$i18n.locale = this.$i18n.locale == "ar" ? "en" : "ar";
      localStorage.setItem("lang", this.$i18n.locale);
      this.$vuetify.rtl = localStorage.getItem("lang") == "ar";
      moment.locale(this.$i18n.locale == "en" ? "en" : "ar-kw");
    },
    ...mapMutations({
      setDrawer: "SET_DRAWER",
      setDrawerMessage: "SET_DRAWER_Message",
    }),
  },
};
</script>

<style lang="scss" scoped>
.fontSize {
  font-size: 11px !important;
}
</style>
<style>
.forum-reply-notify .mdi-bullhorn-variant::before {
  color: orange;
}
.loading-indicator {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
}
.scan-confirmation {
  position: absolute;
  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.8);

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
</style>